import { useEffect, useState } from "react";
import Store from "../../../../Store";
import MainModal from "../MainModal/MainModal";
import "./PlatformEndModal.css";
import MainButton from "../../Buttons/MainButton/MainButton";
import api from "../../../api/api";

function PlatformEndModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getShouldOpenModal = () => {
    return api.get(`/user/should-see-end-modal`);
  };

  useEffect(() => {
    getShouldOpenModal().then(res => setIsModalOpen(res.data.shouldSeeEndModal))
  }, [])

  return (
    <MainModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      className="platform-end-modal"
    >
      <div className="platform-end-modal_wrapper">
        <div className="platform-end-modal__content">
          <p className="platform-end-modal__header">
            {Store.getText("endModalHeader")}
          </p>
          <p className="platform-end-modal__partagraph">
            {Store.getText("endModalParagraph1")}
          </p>
          <p className="platform-end-modal__partagraph">
            {Store.getText("endModalParagraph2")}
          </p>
          <p className="platform-end-modal__partagraph">
            {Store.getText("endModalParagraph3")}
          </p>
        </div>
        <div className="platform-end-modal__button">
          <MainButton onClick={() => setIsModalOpen(false)}>{Store.getText('close')}</MainButton>
        </div>
      </div>
    </MainModal>
  );
}

export default PlatformEndModal;
